import React from "react";

const MintScanLogo = ({ isDark, isHover }) => {
	return (
		<svg
			width="18"
			height="16"
			viewBox="0 0 18 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.4528 0.263008H7.04009C6.88032 0.263161 6.7234 0.305277 6.58502 0.385143C6.44664 0.465008 6.33167 0.57982 6.25162 0.718086L3.04525 6.27121C2.96509 6.40948 2.92287 6.56647 2.92287 6.72629C2.92287 6.88611 2.96509 7.0431 3.04525 7.18137L6.25162 12.7345L7.82838 11.8244L4.88633 6.72631L7.56634 2.08332H12.9288L15.8731 7.18133L17.4499 6.27117L14.2411 0.718086C14.1613 0.579652 14.0464 0.464701 13.908 0.384809C13.7696 0.304917 13.6126 0.262906 13.4528 0.263008Z"
				fill={`${isHover ? (isDark ? "#A7B0BE" : "#414A58") : "#718096"}`}
			/>
			<path
				d="M11.7289 3.2809L10.1521 4.19106L13.0965 9.2891L10.4165 13.932H5.05412L2.10979 8.834L0.533028 9.7442L3.73939 15.2973C3.81929 15.4357 3.93421 15.5506 4.0726 15.6305C4.211 15.7104 4.36798 15.7524 4.52777 15.7524H10.9405C11.1003 15.7522 11.2572 15.7101 11.3955 15.6302C11.5339 15.5504 11.6489 15.4356 11.7289 15.2973L14.9353 9.7442C15.0154 9.60593 15.0577 9.44895 15.0577 9.28912C15.0577 9.1293 15.0154 8.97231 14.9353 8.83404L11.7289 3.2809Z"
				fill={`${isHover ? (isDark ? "#A7B0BE" : "#414A58") : "#718096"}`}
			/>
		</svg>
	);
};

export default MintScanLogo;
