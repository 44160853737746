import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";
import ReactGA from "react-ga4";

ReactGA.initialize("G-EJZ3NV4BM4");

ReactGA.send({
	hitType: "pageview",
	page: window.location.pathname + window.location.search,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
