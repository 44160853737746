import React from "react";

const LogoDark = () => {
	return (
		<svg
			width="116"
			height="20"
			viewBox="0 0 116 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="logo-mobile-size"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0 15.9957H0.99973V17.9951C0.99973 18.5473 1.44733 18.9949 1.99946 18.9949H3.99892V19.9946H1.99946C0.89519 19.9946 0 19.0994 0 17.9951V15.9957ZM0 11.9968H0.99973V7.99784H0V11.9968ZM0 3.99892H0.99973V1.99946C0.99973 1.44733 1.44733 0.99973 1.99946 0.99973H3.99892V0H1.99946C0.895189 0 0 0.89519 0 1.99946V3.99892ZM7.99784 0V0.99973H11.9968V0H7.99784ZM15.9957 0V0.99973H17.9951C18.5473 0.99973 18.9949 1.44733 18.9949 1.99946V3.99892H19.9946V1.99946C19.9946 0.895189 19.0994 0 17.9951 0H15.9957ZM19.9946 7.99784H18.9949V11.9968H19.9946V7.99784ZM19.9946 15.9957H18.9949V17.9951C18.9949 18.5473 18.5473 18.9949 17.9951 18.9949H15.9957V19.9946H17.9951C19.0994 19.9946 19.9946 19.0994 19.9946 17.9951V15.9957ZM11.9968 19.9946V18.9949H7.99784V19.9946H11.9968Z"
				fill="white"
			/>
			<path
				d="M116 7.64009V9.18767H115.244C114.44 9.18767 113.805 9.42161 113.337 9.88948C112.881 10.3454 112.653 10.9992 112.653 11.851V16.5117H110.961V7.73007H112.545L112.689 9.49359H112.527C112.647 8.91775 112.935 8.44987 113.391 8.08997C113.847 7.71807 114.416 7.53212 115.1 7.53212C115.256 7.53212 115.4 7.54412 115.532 7.56811C115.676 7.58011 115.832 7.6041 116 7.64009Z"
				fill="white"
			/>
			<path d="M108.471 16.5116H106.78V3.26714H108.471V16.5116Z" fill="white" />
			<path
				d="M99.4808 16.7275C98.629 16.7275 97.8972 16.5356 97.2854 16.1517C96.6735 15.7558 96.1996 15.2099 95.8637 14.5141C95.5398 13.8183 95.3779 13.0265 95.3779 12.1387C95.3779 11.239 95.5458 10.4412 95.8817 9.74539C96.2176 9.03758 96.6975 8.47973 97.3213 8.07184C97.9452 7.66395 98.689 7.46001 99.5527 7.46001C100.237 7.46001 100.842 7.60397 101.37 7.89189C101.91 8.16781 102.33 8.5817 102.63 9.13355V3.26714H104.303V16.5116H102.792L102.648 14.91C102.36 15.4978 101.934 15.9477 101.37 16.2596C100.818 16.5715 100.189 16.7275 99.4808 16.7275ZM99.8227 15.1979C100.399 15.1979 100.89 15.066 101.298 14.802C101.718 14.5381 102.042 14.1722 102.27 13.7043C102.498 13.2364 102.612 12.6966 102.612 12.0848C102.612 11.4729 102.498 10.9391 102.27 10.4832C102.042 10.0153 101.718 9.64942 101.298 9.38549C100.89 9.12156 100.399 8.98959 99.8227 8.98959C99.2468 8.98959 98.755 9.12756 98.3471 9.40348C97.9392 9.66741 97.6273 10.0333 97.4113 10.5012C97.1954 10.9571 97.0874 11.4849 97.0874 12.0848C97.0874 12.6966 97.1954 13.2364 97.4113 13.7043C97.6273 14.1722 97.9392 14.5381 98.3471 14.802C98.755 15.066 99.2468 15.1979 99.8227 15.1979Z"
				fill="white"
			/>
			<path
				d="M84.9217 12.1027C84.9217 11.2029 85.1196 10.4051 85.5155 9.70933C85.9114 9.01352 86.4513 8.46767 87.1351 8.07178C87.8309 7.67588 88.6227 7.47794 89.5105 7.47794C90.3982 7.47794 91.184 7.67588 91.8678 8.07178C92.5516 8.46767 93.0915 9.01352 93.4874 9.70933C93.8833 10.4051 94.0812 11.2029 94.0812 12.1027C94.0812 13.0024 93.8833 13.8002 93.4874 14.496C93.0915 15.1919 92.5516 15.7377 91.8678 16.1336C91.184 16.5295 90.3982 16.7274 89.5105 16.7274C88.6227 16.7274 87.8309 16.5295 87.1351 16.1336C86.4513 15.7377 85.9114 15.1919 85.5155 14.496C85.1196 13.8002 84.9217 13.0024 84.9217 12.1027ZM86.6312 12.1027C86.6312 12.7145 86.7512 13.2544 86.9911 13.7223C87.2431 14.1901 87.585 14.556 88.0169 14.82C88.4487 15.0839 88.9466 15.2159 89.5105 15.2159C90.0743 15.2159 90.5722 15.0839 91.0041 14.82C91.4359 14.556 91.7718 14.1901 92.0118 13.7223C92.2637 13.2544 92.3897 12.7145 92.3897 12.1027C92.3897 11.4789 92.2637 10.939 92.0118 10.4831C91.7718 10.0153 91.4359 9.64935 91.0041 9.38542C90.5722 9.12149 90.0743 8.98953 89.5105 8.98953C88.9466 8.98953 88.4487 9.12149 88.0169 9.38542C87.585 9.64935 87.2431 10.0153 86.9911 10.4831C86.7512 10.939 86.6312 11.4789 86.6312 12.1027Z"
				fill="white"
			/>
			<path
				d="M76.8383 16.4936H75.1468V3.26714H76.8383V9.06157C77.1143 8.5817 77.5102 8.19781 78.026 7.90989C78.5419 7.60997 79.1417 7.46001 79.8255 7.46001C80.9412 7.46001 81.775 7.78992 82.3269 8.44974C82.8787 9.10956 83.1546 10.0033 83.1546 11.131V16.5116H81.4631V11.5089C81.4631 10.9091 81.3731 10.4292 81.1932 10.0693C81.0252 9.6974 80.7793 9.42748 80.4554 9.25952C80.1434 9.09157 79.7775 9.00759 79.3577 9.00759C78.8178 9.00759 78.3559 9.12756 77.972 9.36749C77.6001 9.60743 77.3182 9.93134 77.1263 10.3392C76.9343 10.7471 76.8383 11.203 76.8383 11.7069V16.4936Z"
				fill="white"
			/>
			<path
				d="M69.1906 16.7276C68.3388 16.7276 67.583 16.5357 66.9232 16.1518C66.2634 15.7559 65.7475 15.216 65.3756 14.5322C65.0037 13.8364 64.8177 13.0326 64.8177 12.1209C64.8177 11.1971 64.9977 10.3873 65.3576 9.69153C65.7295 8.99572 66.2334 8.44986 66.8692 8.05397C67.517 7.65808 68.2668 7.46013 69.1186 7.46013C69.9584 7.46013 70.6842 7.64008 71.296 7.99998C71.9198 8.35989 72.3997 8.86375 72.7356 9.51158C73.0835 10.1594 73.2575 10.9212 73.2575 11.797V12.4268L65.7175 12.4448L65.7535 11.3111H71.5659C71.5659 10.5793 71.344 9.99145 70.9001 9.54757C70.4562 9.10369 69.8624 8.88175 69.1186 8.88175C68.5547 8.88175 68.0689 9.00771 67.661 9.25964C67.2651 9.49958 66.9592 9.85948 66.7432 10.3394C66.5393 10.8072 66.4373 11.3711 66.4373 12.0309C66.4373 13.0866 66.6772 13.9024 67.1571 14.4782C67.637 15.0421 68.3268 15.324 69.2266 15.324C69.8864 15.324 70.4262 15.192 70.8461 14.9281C71.266 14.6642 71.5479 14.2803 71.6919 13.7764H73.2755C73.0595 14.7122 72.5977 15.438 71.8898 15.9538C71.182 16.4697 70.2823 16.7276 69.1906 16.7276Z"
				fill="white"
			/>
			<path
				d="M55.2051 12.1209C55.2051 11.1971 55.385 10.3873 55.7449 9.69153C56.1168 8.99572 56.6267 8.44986 57.2745 8.05397C57.9223 7.65808 58.6721 7.46013 59.5239 7.46013C60.6276 7.46013 61.5454 7.75405 62.2772 8.34189C63.009 8.92973 63.4469 9.70952 63.5908 10.6813H61.8993C61.7553 10.1174 61.4734 9.69153 61.0535 9.40361C60.6336 9.11568 60.1417 8.97172 59.5779 8.97172C59.05 8.97172 58.5822 9.10369 58.1743 9.36762C57.7664 9.61955 57.4485 9.97945 57.2205 10.4473C56.9926 10.9152 56.8786 11.467 56.8786 12.1029C56.8786 12.7387 56.9866 13.2906 57.2025 13.7584C57.4185 14.2143 57.7244 14.5742 58.1203 14.8381C58.5162 15.0901 58.9781 15.216 59.5059 15.216C60.1058 15.216 60.6216 15.0661 61.0535 14.7662C61.4854 14.4662 61.7733 14.0584 61.9173 13.5425H63.6088C63.5008 14.1783 63.2549 14.7362 62.871 15.216C62.4991 15.6839 62.0192 16.0558 61.4314 16.3317C60.8556 16.5957 60.2137 16.7276 59.5059 16.7276C58.6422 16.7276 57.8864 16.5357 57.2385 16.1518C56.6027 15.7679 56.1048 15.234 55.7449 14.5502C55.385 13.8544 55.2051 13.0446 55.2051 12.1209Z"
				fill="white"
			/>
			<path
				d="M48.7416 16.7276C47.8059 16.7276 47.0681 16.4817 46.5282 15.9898C46.0004 15.498 45.7365 14.8561 45.7365 14.0643C45.7365 13.2606 46.0244 12.6187 46.6002 12.1389C47.1761 11.647 47.9798 11.3591 49.0116 11.2751L51.7828 11.0592V10.8072C51.7828 10.3154 51.6928 9.92546 51.5129 9.63754C51.3329 9.33762 51.087 9.12768 50.7751 9.00771C50.4632 8.87575 50.1093 8.80977 49.7134 8.80977C49.0056 8.80977 48.4537 8.95972 48.0578 9.25964C47.6739 9.54757 47.482 9.96146 47.482 10.5013H46.0064C46.0064 9.88947 46.1623 9.35562 46.4743 8.89974C46.7862 8.44386 47.224 8.08996 47.7879 7.83803C48.3637 7.5861 49.0296 7.46013 49.7854 7.46013C50.5172 7.46013 51.153 7.59209 51.6928 7.85602C52.2447 8.10795 52.6706 8.49785 52.9705 9.02571C53.2824 9.54157 53.4384 10.1954 53.4384 10.9872V16.5117H51.9988L51.8188 15.0901C51.5909 15.5939 51.195 15.9958 50.6311 16.2957C50.0793 16.5837 49.4494 16.7276 48.7416 16.7276ZM49.2275 15.414C50.0313 15.414 50.6611 15.174 51.117 14.6942C51.5729 14.2023 51.8008 13.5425 51.8008 12.7147V12.2468L49.5514 12.4268C48.8076 12.4988 48.2678 12.6727 47.9319 12.9486C47.6079 13.2246 47.446 13.5785 47.446 14.0104C47.446 14.4782 47.6019 14.8321 47.9139 15.0721C48.2378 15.3 48.6757 15.414 49.2275 15.414Z"
				fill="white"
			/>
			<path
				d="M43.6782 16.5116H41.9866V3.26714H43.6782V16.5116Z"
				fill="white"
			/>
			<path
				d="M32.7552 3.50096V16.5115H30.9916V3.50096H32.7552ZM36.0483 11.8687H32.3593V10.2851H35.6704C36.5461 10.2851 37.218 10.0512 37.6858 9.58332C38.1537 9.10345 38.3876 8.46163 38.3876 7.65784C38.3876 6.84206 38.1537 6.21223 37.6858 5.76835C37.218 5.31247 36.5701 5.08453 35.7424 5.08453H31.9634V3.50096H36.0483C36.9 3.50096 37.6378 3.67491 38.2617 4.02282C38.8855 4.37073 39.3714 4.8566 39.7193 5.48043C40.0672 6.10426 40.2411 6.83606 40.2411 7.67584C40.2411 8.47962 40.0672 9.19943 39.7193 9.83525C39.3714 10.4711 38.8855 10.9689 38.2617 11.3289C37.6378 11.6888 36.9 11.8687 36.0483 11.8687Z"
				fill="white"
			/>
		</svg>
	);
};

export default LogoDark;
