import React, { useState } from "react";
import TwitterLogo from "./SVG/TwitterLogo";

const Twitter = ({ isDark }) => {
	const [isHover, setIsHover] = useState(false);
	return (
		<div
			className="flex items-center gap-1"
			onMouseEnter={() => {
				setIsHover(true);
			}}
			onMouseLeave={() => {
				setIsHover(false);
			}}
		>
			<TwitterLogo isDark={isDark} isHover={isHover} />{" "}
			<p
				className={`text-[16px] text-grey font-satoshi-bold ${
					isHover ? (isDark ? "text-grey-tint-300" : "text-grey-tint-700") : ""
				} hover:duration-150`}
			>
				X(Twitter)
			</p>
		</div>
	);
};

export default Twitter;
