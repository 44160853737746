import React, { useState } from "react";
import LightMode from "./SVG/LightMode";
import DarkMode from "./SVG/DarkMode";
import LogoLight from "./SVG/LogoLight";
import LogoDark from "./SVG/LogoDark";

const Header = ({ isDark, setIsDark }) => {
	return (
		<div className="flex items-center justify-between px-5 py-5">
			<span>{isDark ? <LogoDark /> : <LogoLight />}</span>

			<span>
				{isDark ? (
					<button
						onClick={() => {
							setIsDark(false);
						}}
					>
						<span className="flex items-center justify-center h-[46px] w-[46px] bg-black-light rounded-full hover:bg-grey-tint-700 hover:duration-150">
							<LightMode />
						</span>
					</button>
				) : (
					<button
						onClick={() => {
							setIsDark(true);
						}}
					>
						<span
							className={`flex items-center justify-center h-[46px] w-[46px] bg-grey-light rounded-full hover:bg-[#DAE4F1] hover:duration-150`}
						>
							<DarkMode />
						</span>
					</button>
				)}
			</span>
		</div>
	);
};

export default Header;
