import React, { useState } from "react";
import GitBookLogo from "./SVG/GitBookLogo";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const GitBook = ({ isDark }) => {
	const [isHover, setIsHover] = useState(false);
	return (
		<div
			className="flex items-center gap-1"
			onMouseEnter={() => {
				setIsHover(true);
			}}
			onMouseLeave={() => {
				setIsHover(false);
			}}
		>
			<GitBookLogo isHover={isHover} isDark={isDark} />
			<p
				className={`text-[16px] text-grey font-satoshi-bold ${
					isDark ? "hover:text-grey-tint-300" : "hover:text-grey-tint-700"
				} hover:duration-150`}
			>
				GitBook
			</p>
		</div>
	);
};

export default GitBook;
