import "./css/App.css";
import Header from "./components/Header";
import LandingPage from "./components/LandingPage";
import Footer from "./components/Footer";
import { useState, useEffect } from "react";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-EJZ3NV4BM4";
ReactGA.initialize(TRACKING_ID);

function App() {
	const [isDark, setIsDark] = useState(true);
	console.log(isDark);

	return (
		<div className={`h-screen w-full relative ${isDark ? "bg-black" : ""} `}>
			<Header setIsDark={setIsDark} isDark={isDark} />
			<LandingPage isDark={isDark} />
			<Footer isDark={isDark} />
		</div>
	);
}

export default App;
