import React, { useState } from "react";
import MintScanLogo from "./SVG/MintScanLogo";

const MintScan = ({ isDark }) => {
	const [isHover, setIsHover] = useState(false);
	return (
		<div
			className="flex items-center gap-1"
			onMouseEnter={() => {
				setIsHover(true);
			}}
			onMouseLeave={() => {
				setIsHover(false);
			}}
		>
			<MintScanLogo isDark={isDark} isHover={isHover} />
			<p
				className={`text-[16px] text-grey font-satoshi-bold ${
					isHover ? (isDark ? "text-grey-tint-300" : "text-grey-tint-700") : ""
				} hover:duration-150`}
			>
				MintScan
			</p>
		</div>
	);
};

export default MintScan;
