import React from "react";

const Star = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10 19.1111C15.0319 19.1111 19.1111 15.0319 19.1111 10C19.1111 4.96808 15.0319 0.888889 10 0.888889C4.96808 0.888889 0.888889 4.96808 0.888889 10C0.888889 15.0319 4.96808 19.1111 10 19.1111ZM20 10C20 15.5229 15.5229 20 10 20C4.47716 20 0 15.5229 0 10C0 4.47716 4.47716 0 10 0C15.5229 0 20 4.47716 20 10Z"
				fill="white"
			/>
			<path
				d="M11.8544 2.49673L12.2578 8.09785L17.7089 9.44451L12.5067 11.559L12.91 17.1601L9.29223 12.8656L4.09001 14.9812L7.05556 10.2123L3.43668 5.91785L8.88779 7.26562L11.8544 2.49673Z"
				fill="white"
			/>
		</svg>
	);
};

export default Star;
