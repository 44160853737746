import React, { useState } from "react";

const TwitterLogo = ({ isHover, isDark }) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.367966 0.400002L6.23281 8.95L0.492184 15.6H1.76406L6.79453 9.76875L10.7945 15.6H15.6L9.47265 6.66797L14.8797 0.400002H13.6117L8.91015 5.84688L5.17343 0.400002H0.367966ZM1.88672 1.2H4.75234L14.0812 14.8H11.2156L1.88672 1.2Z"
				fill={`${isHover ? (isDark ? "#A7B0BE" : "#414A58") : "#718096"}`}
			/>
		</svg>
	);
};

export default TwitterLogo;
