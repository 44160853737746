import React, { useState } from "react";
import RightArrow from "../components/SVG/RightArrow";
import Star from "./SVG/Star";

const LandingPage = ({ isDark }) => {
	const [isHover, setIsHover] = useState(false);
	return (
		<div className="absolute top-1/2 -translate-y-1/2 left-1/2 transform -translate-x-1/2 text-center w-full flex flex-col ">
			<h1
				className={`heading-size text-[64px] font-satoshi-bold  ${
					isDark ? "text-white" : "text-black"
				} `}
			>
				Own a piece <br /> of web3 infrastructure
			</h1>
			<p className="text-grey text-[20px] font-satoshi-medium mt-[27px] main-subtext ">
				Placehodlr tokenizes yield from validators.
			</p>
			<div className="buttons flex items-center justify-center gap-8 mt-10">
				<a
					href="https://www.stargaze.zone/l/stars1ckd7xarls3e32luvsa4mqv6h5hy792ekylar4rfzajzlmzgpd7qsww8kvd"
					target="_blank"
				>
					<button
						className={`buy-ownership  rounded-lg w-[198px] h-[56px] flex items-center justify-center gap-2 font-satoshi-bold hover:duration-150 bg-pink hover:bg-pink-600`}
					>
						<Star />

						<p className=" text-[18px] text-white">Buy Ownership</p>
					</button>
				</a>
				<a
					href="https://docs.placehodlr.fun/first-validator-nft-mint/how-to-buy-ownership"
					target="_blank"
				>
					<button
						className={`w-[172px] h-[56px] justify-center  rounded-lg learn-more flex items-center gap-2  ${
							isDark
								? "hover:bg-black-light hover:text-white text-grey-tint-400"
								: "hover:bg-grey-light hover:text-black text-grey-tint-300"
						} hover:duration-150  `}
						onMouseEnter={() => {
							setIsHover(true);
						}}
						onMouseLeave={() => {
							setIsHover(false);
						}}
					>
						<p
							className={` font-satoshi-bold  text-[18px]  
                       `}
						>
							Learn More
						</p>{" "}
						<RightArrow isHover={isHover} isDark={isDark} />
					</button>
				</a>
			</div>
		</div>
	);
};

export default LandingPage;
