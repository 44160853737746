import React from "react";

import GitBook from "./GitBook";
import Twitter from "./Twitter";
import MintScan from "./MintScan";

const Footer = ({ isDark }) => {
	return (
		<div className="absolute bottom-[20px] left-1/2 transform -translate-x-1/2 flex justify-center gap-6 ">
			<a href="https://twitter.com/Placehodlr_fun" target="_blank">
				<Twitter isDark={isDark} />
			</a>
			<a
				href="https://www.mintscan.io/cosmos/validators/cosmosvaloper103agss48504gkk3la5xcg5kxplaf6ttnuv234h"
				target="_blank"
			>
				<MintScan isDark={isDark} />
			</a>
			<a href="https://docs.placehodlr.fun" target="_blank">
				<GitBook isDark={isDark} />
			</a>
		</div>
	);
};

export default Footer;
