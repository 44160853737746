import React from "react";

const RightArrow = ({ isDark, isHover }) => {
	return (
		<svg
			width="7"
			height="10"
			viewBox="0 0 7 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.59125 8.8175L5.40875 5L1.59125 1.1825"
				stroke={`${isDark ? "#576375" : "#c4c4c4"}`}
				stroke-width="1.4"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>

		// <svg
		// 	width="20"
		// 	height="20"
		// 	viewBox="0 0 20 20"
		// 	fill="none"
		// 	xmlns="http://www.w3.org/2000/svg"
		// >
		// 	<path
		// 		d="M7.59125 13.8175L11.4088 10L7.59125 6.1825"
		// 		stroke={`${isHover ? "#576375" : "#A7B0BE"}`}
		// 		stroke-width="1.4"
		// 		stroke-linecap="round"
		// 		stroke-linejoin="round"
		// 	/>
		// </svg>
	);
};

export default RightArrow;
