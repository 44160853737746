import React from "react";

const DarkMode = () => {
	return (
		<svg
			width="19"
			height="18"
			viewBox="0 0 19 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.5088 9.5925C16.3908 10.8692 15.9117 12.0858 15.1275 13.1001C14.3433 14.1144 13.2864 14.8843 12.0806 15.3199C10.8747 15.7555 9.56976 15.8386 8.3184 15.5596C7.06704 15.2805 5.92102 14.6509 5.01445 13.7443C4.10787 12.8378 3.47823 11.6917 3.19921 10.4404C2.92018 9.18901 3.00331 7.88406 3.43887 6.67822C3.87443 5.47238 4.6444 4.41552 5.65869 3.63131C6.67297 2.8471 7.88962 2.36798 9.16628 2.25C8.41884 3.2612 8.05916 4.50709 8.15268 5.76106C8.24619 7.01503 8.78667 8.19379 9.67583 9.08295C10.565 9.9721 11.7437 10.5126 12.9977 10.6061C14.2517 10.6996 15.4976 10.3399 16.5088 9.5925Z"
				fill="#1A202C"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.38407 2.12726C9.43245 2.21311 9.42589 2.31936 9.36732 2.39861C8.65547 3.36165 8.31292 4.54821 8.40198 5.74247C8.49104 6.93673 9.00579 8.05936 9.8526 8.90618C10.6994 9.75299 11.822 10.2677 13.0163 10.3568C14.2106 10.4459 15.3971 10.1033 16.3602 9.39146C16.4394 9.33289 16.5457 9.32633 16.6315 9.37471C16.7174 9.42309 16.7668 9.51739 16.7577 9.61551C16.6354 10.9394 16.1385 12.2012 15.3252 13.253C14.512 14.3049 13.416 15.1034 12.1655 15.555C10.915 16.0067 9.5617 16.0929 8.26399 15.8036C6.96628 15.5142 5.77782 14.8613 4.83767 13.9211C3.89752 12.981 3.24456 11.7925 2.9552 10.4948C2.66584 9.19708 2.75205 7.8438 3.20374 6.5933C3.65543 5.34279 4.45392 4.24679 5.50577 3.43354C6.55762 2.62028 7.81933 2.12341 9.14327 2.00106C9.24139 1.992 9.33569 2.04141 9.38407 2.12726ZM8.65403 2.57094C7.62139 2.75379 6.64548 3.18438 5.8116 3.8291C4.83488 4.58426 4.09343 5.60198 3.674 6.76316C3.25458 7.92434 3.17452 9.18095 3.44321 10.386C3.7119 11.591 4.31822 12.6946 5.19122 13.5676C6.06422 14.4406 7.16779 15.0469 8.37281 15.3156C9.57783 15.5843 10.8344 15.5042 11.9956 15.0848C13.1568 14.6654 14.1745 13.9239 14.9297 12.9472C15.5744 12.1133 16.005 11.1374 16.1878 10.1047C15.224 10.6748 14.1047 10.9393 12.9791 10.8554C11.6654 10.7574 10.4305 10.1912 9.49905 9.25973C8.56755 8.32823 8.00133 7.09334 7.90337 5.77966C7.81943 4.65409 8.08402 3.53475 8.65403 2.57094Z"
				fill="#1A202C"
			/>
		</svg>
	);
};

export default DarkMode;
