import React from "react";

const LightMode = () => {
	return (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_2229_136)">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.24121 6.90454C7.58436 6.90454 6.24121 8.24769 6.24121 9.90454C6.24121 11.5614 7.58436 12.9045 9.24121 12.9045C10.8981 12.9045 12.2412 11.5614 12.2412 9.90454C12.2412 8.24769 10.8981 6.90454 9.24121 6.90454ZM4.74121 9.90454C4.74121 7.41926 6.75593 5.40454 9.24121 5.40454C11.7265 5.40454 13.7412 7.41926 13.7412 9.90454C13.7412 12.3898 11.7265 14.4045 9.24121 14.4045C6.75593 14.4045 4.74121 12.3898 4.74121 9.90454Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.24121 0.904541C9.65542 0.904541 9.99121 1.24033 9.99121 1.65454V3.15454C9.99121 3.56875 9.65542 3.90454 9.24121 3.90454C8.827 3.90454 8.49121 3.56875 8.49121 3.15454V1.65454C8.49121 1.24033 8.827 0.904541 9.24121 0.904541Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.24121 15.9045C9.65542 15.9045 9.99121 16.2403 9.99121 16.6545V18.1545C9.99121 18.5688 9.65542 18.9045 9.24121 18.9045C8.827 18.9045 8.49121 18.5688 8.49121 18.1545V16.6545C8.49121 16.2403 8.827 15.9045 9.24121 15.9045Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M2.87586 3.53925C3.16875 3.24636 3.64363 3.24636 3.93652 3.53925L5.00152 4.60425C5.29441 4.89714 5.29441 5.37202 5.00152 5.66491C4.70863 5.9578 4.23375 5.9578 3.94086 5.66491L2.87586 4.59991C2.58297 4.30702 2.58297 3.83214 2.87586 3.53925Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M13.4809 14.1442C13.7738 13.8513 14.2487 13.8513 14.5416 14.1442L15.6066 15.2092C15.8995 15.5021 15.8995 15.977 15.6066 16.2699C15.3137 16.5628 14.8388 16.5628 14.5459 16.2699L13.4809 15.2049C13.188 14.912 13.188 14.4371 13.4809 14.1442Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M0.241211 9.90454C0.241211 9.49033 0.576997 9.15454 0.991211 9.15454H2.49121C2.90542 9.15454 3.24121 9.49033 3.24121 9.90454C3.24121 10.3188 2.90542 10.6545 2.49121 10.6545H0.991211C0.576997 10.6545 0.241211 10.3188 0.241211 9.90454Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M15.2412 9.90454C15.2412 9.49033 15.577 9.15454 15.9912 9.15454H17.4912C17.9054 9.15454 18.2412 9.49033 18.2412 9.90454C18.2412 10.3188 17.9054 10.6545 17.4912 10.6545H15.9912C15.577 10.6545 15.2412 10.3188 15.2412 9.90454Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M5.00152 14.1442C5.29441 14.4371 5.29441 14.912 5.00152 15.2049L3.93652 16.2699C3.64363 16.5628 3.16875 16.5628 2.87586 16.2699C2.58297 15.977 2.58297 15.5021 2.87586 15.2092L3.94086 14.1442C4.23375 13.8513 4.70863 13.8513 5.00152 14.1442Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M15.6066 3.53925C15.8995 3.83214 15.8995 4.30702 15.6066 4.59991L14.5416 5.66491C14.2487 5.9578 13.7738 5.9578 13.4809 5.66491C13.188 5.37202 13.188 4.89714 13.4809 4.60425L14.5459 3.53925C14.8388 3.24636 15.3137 3.24636 15.6066 3.53925Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2229_136">
					<rect
						width="18"
						height="18"
						fill="white"
						transform="translate(0.241211 0.904541)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default LightMode;
